<ng-template #template>
  <div
    class="hidden w-[60%] flex-col justify-end bg-purple-200 bg-[url('~src/assets/img/login/pattern.svg')] bg-cover bg-no-repeat
      pb-[10%] lg:flex lg:bg-[0%_-100%]"
  >
    <div class="mx-auto flex flex-col gap-6 px-10">
      <div class="text-center text-4xl font-bold text-white">Your knowledge diffusion just above you</div>
      <div class="text-center text-xl text-white">
        Dive into a world of exclusive tools designed to skyrocket your productivity and amplify your success. Join now
        for a game-changing experience!
      </div>
    </div>
  </div>
</ng-template>
